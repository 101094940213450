import { Col, Row } from "antd";
import Assortments from "app/assortment/assortments";

export default function FabricVerticalPage() {
  return (
    <>
      <Row gutter={[32, 16]}>
        <Col md={12}>
          <Assortments type="fabric-vertical" />
        </Col>
      </Row>
    </>
  );
}
