import { Col, Row } from "antd";
import Assortments from "app/assortment/assortments";


export default function TubePage() {
  return (
    <>
      <Row gutter={[32, 16]}>
        <Col md={12}>
          <Assortments type="tube" />
        </Col>
      </Row>
    </>
  );
}
