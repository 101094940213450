import { Col, List, Row, Typography } from "antd";
import Assortments from "app/assortment/assortments";

const { Text } = Typography;

const data = [
  { abbrev: "S", description: "Napęd przewodowy, mechaniczne krańcówki" },
  { abbrev: "BD", description: "Napęd przewodowy z detekcją przeszkód" },
  { abbrev: "R", description: "Napęd radiowy, mechaniczne krańcówki" },
  { abbrev: "EVY", description: "Napęd radiowy z detekcją przeszkód" },
  { abbrev: "EVS", description: "Napęd radiowy z detekcją przeszkód" },
  { abbrev: "EMS", description: "Napęd z awaryjnym otwieraniem" },
];

export default function BlindControlPage() {
  return (
    <>
      <Row gutter={[32, 16]}>
        <Col md={12}>
          <Assortments type="blind-control" />
        </Col>
        <Col md={6} md-offset={6}>
          <List
            bordered
            header="Legenda"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Row  style={{width: "100%"}}>
                  <Col flex="50px"><Text strong>{item.abbrev}</Text></Col>
                  <Col flex="auto">{item.description}</Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
