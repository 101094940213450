import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import type { GetProp, TableProps } from "antd";
import { Space, Table, Typography } from "antd";
import axios from "app/auth/axios-interceptor";
import dayjs from "dayjs";

import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import AddButton from "app/common/add-button";
import DownloadOrdersButton from "app/orders/download-orders-button";
import StatusButtons from "./status-buttons";
import StatusFilterButton from "app/orders/status-filter-button";
import PagedModel from "app/common/PagedModel";
import { ORDER_STATUS_NAMES, OrderStatus } from "./OrderStatus";
import SearchDropdown from "app/common/search-dropdown";
import DatePickerDropdown from "../common/date-picker-dropdown";
import OrderStatusTag from "./order-status-tag";
import { PRODUCT_FAMILY_NAMES } from "app/products/ProductFamily";

import Customer, { getCustomerName } from "app/customers/Customer";
import OrderDetails from "./order/recipient/OrderDetails";
import OrderBase from "./OrderBase";

type ColumnsType<T extends object = object> = TableProps<T>["columns"];
type TablePaginationConfig = Exclude<
  GetProp<TableProps, "pagination">,
  boolean
>;

const { Text } = Typography;

interface OrderSummary {
  id: string;
  details: OrderDetails;
  products: string[];
}

interface TableParams {
  pagination?: TablePaginationConfig;
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

interface SearchParams {
  number?: string;
  customer?: string;
  subcontract?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  realizeDateFrom?: string;
  realizeDateTo?: string;
  families?: string;
  statuses?: string;
}

const DEFAULT_PAGE_SIZE = 10;

export default function OrdersPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrderSummary[]>([]);

  const [selectedOrders, setSelectedOrders] = useState<OrderBase[]>([]);

  const columns: ColumnsType<OrderSummary> = [
    {
      title: "Numer",
      dataIndex: ["details", "number"],
      filterDropdown: (props) => <SearchDropdown {...props} />,
      render: (text, record) => (
        <Link to={`/zamowienia/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Klient",
      dataIndex: ["details", "recipient", "customer"],
      filterDropdown: (props) => <SearchDropdown {...props} />,
      render: (customer: Customer) => getCustomerName(customer),
    },
    {
      title: "Podzlecenie",
      dataIndex: ["details", "recipient", "subcontract"],
      filterDropdown: (props) => <SearchDropdown {...props} />,
      responsive: ["lg"],
    },
    {
      title: "Wystawienie",
      dataIndex: ["details", "creationDate"],
      filterDropdown: (props) => <DatePickerDropdown {...props} />,
      render: (text: string) => dayjs.utc(text).local().format("DD.MM.YYYY"),
      responsive: ["md"],
    },
    {
      title: "Realizacja",
      dataIndex: ["details", "realizeDate"],
      filterDropdown: (props) => <DatePickerDropdown {...props} />,
      render: (text: string) =>
        text && dayjs.utc(text).local().format("DD.MM.YYYY HH:mm"),
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: ["details", "status"],
      filters: [...ORDER_STATUS_NAMES].map(([k, v]) => ({
        text: v,
        value: k,
      })),
      render: (status: OrderStatus) => <OrderStatusTag status={status} />,
      responsive: ["md"],
    },
    {
      title: "Produkty",
      dataIndex: "product",
      render: (_, record) => (
        <Space direction="vertical">
          {record.products.map((up) => (
            <Text key={up}>{up}</Text>
          ))}
        </Space>
      ),
      filters: [...PRODUCT_FAMILY_NAMES].map(([k, v]) => ({
        text: v,
        value: k,
      })),
      responsive: ["lg"],
    },
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizeOptions: [
        DEFAULT_PAGE_SIZE,
        DEFAULT_PAGE_SIZE * 2,
        DEFAULT_PAGE_SIZE * 5,
      ],
    },
  });

  useEffect(fetchData, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    JSON.stringify(tableParams.filters),
  ]);

  function fetchData() {
    setLoading(true);

    const searchParams = getSearchParams(tableParams);

    const params = {
      page: (tableParams.pagination?.current || 1) - 1,
      size: tableParams.pagination?.pageSize,
      ...searchParams,
    };

    axios
      .get<PagedModel<OrderSummary>>(`/api/orders`, { params })
      .then((response) => {
        setData(response.data.content);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.page.totalElements,
          },
        });
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  const handleTableChange: TableProps<OrderSummary>["onChange"] = (
    pagination,
    filters
  ) => {
    setTableParams({
      pagination,
      filters,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  function getSearchParams(params: TableParams): SearchParams {
    const filters = params.filters;
    if (!filters) {
      return {};
    }

    return {
      number: filters["details.number"]?.[0] as string,
      customer: filters["details.recipient.customer"]?.[0] as string,
      subcontract: filters["details.recipient.subcontract"]?.[0] as string,
      statuses: filters["details.status"]?.join(",") as string,
      creationDateFrom: filters["details.creationDate"]?.[0] as string,
      creationDateTo: filters["details.creationDate"]?.[1] as string,
      realizeDateFrom: filters["details.realizeDate"]?.[0] as string,
      realizeDateTo: filters["details.realizeDate"]?.[1] as string,
      families: filters["product"]?.join(",") as string,
    };
  }

  function changeStatuses(statuses: string[]) {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        "details.status": statuses,
      },
    });
  }

  function onSelect(ids: React.Key[]) {
    const selected: OrderBase[] = data
      .filter((o) => ids.includes(o.id))
      .map((o) => ({ id: o.id, details: o.details }));
    setSelectedOrders(selected);
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => onSelect(selectedRowKeys),
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={16}>
      <Space wrap>
        <Authorized roles={[...Roles.orderCreate, ...Roles.orderEdit]}>
          <Link to="/zamowienia/dodaj">
            <AddButton />
          </Link>
        </Authorized>

        <StatusFilterButton
          onStatusesChange={(statuses) => changeStatuses(statuses)}
        />

        <StatusButtons orders={selectedOrders} onChange={() => fetchData()} />

        <DownloadOrdersButton
          orders={selectedOrders}
          onChange={() => fetchData()}
        />
      </Space>

      <Table<OrderSummary>
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        rowSelection={rowSelection}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </Space>
  );
}
