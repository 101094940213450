import { Button, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { ClockCircleOutlined, DownOutlined } from "@ant-design/icons";

interface Props {
  onStatusesChange: (statuses: string[]) => void;
}

const incomplete = ["PENDING", "CREATED", "SENT", "RECEIVED"];
const withdrawn = ["WITHDRAWN"];

export default function StatusFilterButton({ onStatusesChange }: Props) {
  const items: MenuProps["items"] = [
    {
      key: "incomplete",
      label: "Nieukończone",
      icon: <ClockCircleOutlined />,
    },
    {
      key: "withdrawn",
      label: "Wycofane",
      icon: <ClockCircleOutlined />,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "incomplete":
        onStatusesChange(incomplete);
        break;
      case "withdrawn":
        onStatusesChange(withdrawn);
        break;
    }
  };

  return (
    <Dropdown menu={{ items, onClick }}>
      <Button icon={<DownOutlined />} className="yellow-btn">
        Filtruj
      </Button>
    </Dropdown>
  );
}
