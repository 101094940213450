import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Col, Menu, Row, Layout, Button } from "antd";
import type { MenuProps } from "antd";

import { Roles } from "app/auth/roles";
import { useAuth } from "app/auth/auth-provider";

const { Header } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export default function AppHeader() {
  const auth = useAuth();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState<string[]>();

  useEffect(() => {
    const current = [location.pathname.split("/").filter((i) => i)[0]];
    setCurrentLocation(current);
  }, [location]);

  const items: MenuItem[] = [
    { key: "glowna", label: <NavLink to="/">Główna</NavLink> },
  ];

  if (auth.hasAnyRole(Roles.orderView)) {
    items.push({
      key: "zamowienia",
      label: <NavLink to="/zamowienia">Zamówienia</NavLink>,
    });
  }

  if (auth.hasAnyRole(Roles.customerView)) {
    items.push({
      key: "klienci",
      label: <NavLink to="/klienci">Klienci</NavLink>,
    });
  }

  if (auth.hasAnyRole(Roles.productView)) {
    items.push({
      key: "produkty",
      label: <NavLink to="/produkty">Produkty</NavLink>,
    });
  }

  if (auth.hasAnyRole(Roles.assortmentView)) {
    items.push({
      key: "equipment",
      label: "Wyposażenie",
      children: [
        {
          key: "tkaniny",
          label: <NavLink to="/tkaniny">Tkaniny</NavLink>,
        },
        {
          key: "osprzet-markiz",
          label: <NavLink to="/osprzet-markiz">Osprzęt Markiz</NavLink>,
        },
        {
          key: "sterowanie-rolet",
          label: <NavLink to="/sterowanie-rolet">Sterowanie Rolet</NavLink>,
        },
        {
          key: "tkaniny-pionowych",
          label: <NavLink to="/tkaniny-pionowych">Tkaniny Żaluzji Pionowych</NavLink>,
        },
        {
          key: "tuba",
          label: <NavLink to="/tuba">Tuba Szalunkowa</NavLink>,
        },
      ],
    });
  }

  if (auth.hasAnyRole(Roles.statView)) {
    items.push({
      key: "statystyki",
      label: <NavLink to="/statystyki">Statystyki</NavLink>,
    });
  }

  if (auth.hasAnyRole(Roles.userView)) {
    items.push({
      key: "Użytkownicy",
      label: <NavLink to="/uzytkownicy">Użytkownicy</NavLink>,
    });
  }

  return (
    <Header>
      <Row wrap={false}>
        <Col flex="auto">
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["glowna"]}
            items={items}
            selectedKeys={currentLocation}
          />
        </Col>
        <Col flex="none">
          <Button onClick={() => auth.logout()}>Wyloguj</Button>
        </Col>
      </Row>
    </Header>
  );
}
