import { Flex, Input, List, Modal } from "antd";
import DeleteButton from "../common/delete-button";
import AddButton from "../common/add-button";
import { useEffect, useState } from "react";
import AmountGroupItems from "./amount-group-items";

export default function AmountItemModal({
  visible,
  selected,
  onSave,
  onDelete,
  onCancel,
}: {
  visible: boolean;
  selected?: AmountGroupItems;
  onSave: (id: string, newItems: string[]) => void;
  onDelete: (id: string) => void;
  onCancel: () => void;
}) {
  const [newItem, setNewItem] = useState<string>("");
  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    if (selected) {
      setItems(selected.items);
    }
  }, [selected]);

  function afterClose() {
    setNewItem("");
    setItems([]);
  }

  function addItem() {
    const newValues = [...items, newItem];
    setItems(newValues);
    setNewItem("");
    // dont allow duplicates?
  }

  function removeItem(value: string) {
    const newValues = items.filter((v) => v !== value);
    setItems(newValues);
  }

  return (
    <Modal
      title={"Pozycje"}
      open={visible}
      onOk={() => selected && onSave(selected.id, items)}
      onCancel={onCancel}
      afterClose={afterClose}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <DeleteButton
            title="Usuń grupę"
            onClick={() => selected && onDelete(selected.id)}
          />
          <CancelBtn />
          <OkBtn />
        </>
      )}
    >
      <List
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(value) => (
          <List.Item
            actions={[
              <DeleteButton key={value} onClick={() => removeItem(value)} />,
            ]}
          >
            {value}
          </List.Item>
        )}
      />
      <Flex align="space-between" gap="small" style={{ paddingRight: "8px" }}>
        <Input
          value={newItem}
          onChange={(event) => setNewItem(event.target.value)}
        />
        <AddButton onClick={() => addItem()} />
      </Flex>
    </Modal>
  );
}
