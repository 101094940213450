import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import { AmountFormItem } from "./amount-form-item";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";

export default function AmountForm({
  groupId,
  item,
  onSave,
}: {
  groupId: string;
  item: { title: string; amount: number };
  onSave: (values: AmountFormItem) => void;
}) {
  const [saveDisabled, setSaveDisabled] = useState(true);

  function onFinish(values: AmountFormItem) {
    onSave(values);
    setSaveDisabled(true);
  }

  return (
    <Form<AmountFormItem>
      layout="inline"
      onFinish={(values) => onFinish(values)}
    >
      <Form.Item name="groupId" initialValue={groupId} hidden>
        <Input />
      </Form.Item>
      <Row style={{ width: "100%" }} wrap={false}>
        <Col flex="auto">
          <Form.Item name="title" initialValue={item.title}>
            <span className="ant-form-text">{item.title}</span>
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item name="amount" initialValue={item.amount} noStyle>
            <InputNumber
              onChange={() => setSaveDisabled(false)}
              style={{ width: "60px" }}
              min={0}
            />
          </Form.Item>
        </Col>
        <Authorized roles={Roles.assortmentEdit}>
          <Col flex="none">
            <Form.Item>
              <Button
                disabled={saveDisabled}
                htmlType="submit"
                icon={<CheckOutlined />}
                className="green-btn"
              ></Button>
            </Form.Item>
          </Col>
        </Authorized>
      </Row>
    </Form>
  );
}
