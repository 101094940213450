import { Select } from "antd";
import type { SelectProps } from "antd";
import ProductAttribute from "app/products/ProductAttribute";

export default function MultiSelectField({
  attribute,
  value,
  onChange,
}: {
  attribute: ProductAttribute;
  value?: string;
  onChange?: (value: string) => void;
}) {

  const options: SelectProps["options"] = attribute.values.map((v) => ({
    label: v,
    value: v,
  }));

  function onValuesChange(values: string[]) {
    onChange?.(values.join(", "));
  }

  return (
    <Select
      mode="multiple"
      allowClear
      options={options}
      value={value?.split(",").map(v => v.trim())}
      onChange={onValuesChange}
    />
  );
}
