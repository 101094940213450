import { Form, Input, Radio } from "antd";
import { FormProps } from "antd/lib";
import { useEffect } from "react";
import axios from "app/auth/axios-interceptor";
import User from "./User";
import { USER_GROUP_NAMES, UserGroup } from "./UserGroup";
import { Link } from "react-router-dom";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";
import SaveButton from "app/common/save-button";
import Customer, { getCustomerName } from "app/customers/Customer";

type UserForm = {
  username: string;
  firstName: string;
  lastName?: string;
  group: UserGroup;
  customer?: {
    id: string;
    name: string;
  };
};

interface CustomerFieldProps {
  value?: Customer;
}

const GROUPS: UserGroup[] = ["MANUFACTURE", "OFFICE", "WHOLESALER"];

export default function UserForm({
  username,
  customerId,
  onSave,
}: {
  username?: string;
  customerId?: string;
  onSave: (user: User) => void;
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (username) {
      axios
        .get<User>(`/api/users/${username}`)
        .then(async (response) => {
          const user = response.data;

          const customer = await getCustomer(user.customerId);

          const userForm = {
            username: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            group: user.groups.find((group) => GROUPS.includes(group)),
            customer: customer,
          };

          form.setFieldsValue(userForm);
        })
        .catch(console.error);
    }
  }, [username]);

  useEffect(() => {
    if (customerId) {
      axios
        .get<Customer>(`/api/customers/${customerId}`)
        .then((response) => {
          const customer = response.data;

          const userForm = {
            // username??
            group: "WHOLESALER",
            customer: customer,
          };

          form.setFieldsValue(userForm);
        })
        .catch(console.error);
    }
  }, [customerId]);

  async function getCustomer(customerId: string | undefined) {
    if (!customerId) {
      return Promise.resolve();
    }
    return axios
      .get<Customer>(`/api/customers/${customerId}`)
      .then((response) => response.data);
  }

  const onFinish: FormProps<UserForm>["onFinish"] = (values: UserForm) => {
    const user: User = {
      username: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      groups: [values.group],
      customerId: values.customer?.id,
    };
    onSave(user);
  };

  const groupOptions = GROUPS.map((group) => ({
    value: group,
    label: USER_GROUP_NAMES.get(group),
  }));

  const CustomerField: React.FC<CustomerFieldProps> = (props) => {
    if (!props.value) {
      return "-";
    }

    return (
      <>
        <Link to={`/klienci/${props.value.id}`}>
          {getCustomerName(props.value)}
        </Link>
      </>
    );
  };

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item<UserForm>
          label="Login"
          name="username"
          rules={[
            { required: true, message: "Podaj login" },
            {
              pattern: /^[a-z]+$/,
              message: "Podaj jedno słowo używając tylko małych liter",
            },
          ]}
        >
          <Input disabled={!!username} />
        </Form.Item>

        <Form.Item<UserForm>
          label="Imię"
          name="firstName"
          rules={[{ required: true, message: "Podaj imię" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<UserForm> label="Nazwisko" name="lastName">
          <Input />
        </Form.Item>

        <Form.Item<UserForm>
          label="Grupa"
          name="group"
          initialValue={GROUPS[0]}
          rules={[{ required: true, message: "Podaj grupę" }]}
        >
          <Radio.Group options={groupOptions} optionType="button" />
        </Form.Item>

        <Form.Item<UserForm> hidden name={["customer", "id"]}>
          <Input />
        </Form.Item>

        <Form.Item<UserForm> label="Klient" name="customer">
          <CustomerField />
        </Form.Item>

        <Authorized roles={Roles.customerEdit}>
          <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
            <SaveButton onClick={form.submit} />
          </Form.Item>
        </Authorized>
      </Form>
    </>
  );
}
