import { Button, Card, Space } from "antd";
import AddButton from "app/common/add-button";
import { useEffect, useState } from "react";
import axios from "app/auth/axios-interceptor";
import { Assortment } from "./Assortment";
import AmountForm from "app/assortment/amount-form";
import { AmountFormItem } from "app/assortment/amount-form-item";
import AmountGroupModal from "app/assortment/amount-group-modal";
import AmountItemModal from "app/assortment/amount-item-modal";
import AmountGroupItems from "app/assortment/amount-group-items";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";

export default function Assortments({ type }: { type: string }) {
  const [fittings, setFittings] = useState<Assortment[]>([]);

  const [itemsModalVisible, setItemsModalVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<AmountGroupItems>();
  const [groupModalVisible, setGroupModalVisible] = useState<boolean>(false);

  useEffect(() => {
    fetchFittings();
  }, []);

  function fetchFittings() {
    axios
      .get<Assortment[]>(`api/assortment`, { params: { type } })
      .then((resp) => {
        setFittings(resp.data);
      })
      .catch(console.error);
  }

  function onEditClick(assortment: Assortment) {
    setSelected({
      id: assortment.id,
      items: assortment.items.map((i) => i.title),
    });
    setItemsModalVisible(true);
  }

  function saveItems(id: string, items: string[]) {
    const data = { titles: items };
    axios
      .put(`/api/assortment/${id}`, data)
      .then(() => {
        setItemsModalVisible(false);

        fetchFittings();
      })
      .catch(console.error);
  }

  function saveAmount(item: AmountFormItem) {
    const data = { title: item.title, amount: item.amount };
    axios
      .put(`/api/assortment/${item.groupId}/items`, data)
      .then(() => {
        fetchFittings();
      })
      .catch(console.error);
  }

  function saveGroup(newGroup: string) {
    const data = { title: newGroup };
    axios
      .post(`api/assortment`, data, { params: { type } })
      .then(() => {
        setGroupModalVisible(false);

        fetchFittings();
      })
      .catch(console.error);
  }

  function removeGroup(id: string) {
    axios
      .delete(`/api/assortment/${id}`)
      .then(() => {
        setItemsModalVisible(false);

        fetchFittings();
      })
      .catch(console.error);
  }

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Authorized roles={Roles.assortmentEdit}>
          <AddButton onClick={() => setGroupModalVisible(true)} />
        </Authorized>
        <Space direction="vertical" size={16} style={{ width: "100%" }}>
          {fittings.map((assortment) => (
            <Card
              key={assortment.title}
              title={assortment.title}
              size="small"
              extra={
                <Authorized roles={Roles.assortmentEdit}>
                  <Button type="link" onClick={() => onEditClick(assortment)}>
                    Edytuj
                  </Button>
                </Authorized>
              }
            >
              {assortment.items.map((item) => (
                <AmountForm
                  key={item.title}
                  groupId={assortment.id}
                  item={item}
                  onSave={saveAmount}
                />
              ))}
            </Card>
          ))}
        </Space>
      </Space>

      <AmountItemModal
        visible={itemsModalVisible}
        selected={selected}
        onSave={saveItems}
        onDelete={removeGroup}
        onCancel={() => setItemsModalVisible(false)}
      />

      <AmountGroupModal
        visible={groupModalVisible}
        onSave={saveGroup}
        onCancel={() => setGroupModalVisible(false)}
      />
    </>
  );
}
