import { useEffect, useState } from "react";
import { WarningTwoTone } from "@ant-design/icons";
import { Form, Input, InputNumber, Select, Tooltip } from "antd";
import axios from "app/auth/axios-interceptor";

import Authorized from "app/auth/authorized";
import SaveButton from "app/common/save-button";
import ProductChooser from "app/orders/order/items/product-chooser";
import { Roles } from "app/auth/roles";
import TextArea from "antd/es/input/TextArea";
import FormulaField from "./formula-field";
import OrderItem from "./OrderItem";
import Product from "app/products/Product";
import ProductAttribute from "app/products/ProductAttribute";
import { useAuth } from "app/auth/auth-provider";
import { OrderStatus } from "app/orders/OrderStatus";
import MultiSelectField from "./multi-select-field";

export default function OrderItemForm({
  value,
  status,
  onSave,
}: {
  value?: OrderItem;
  status: OrderStatus;
  onSave: (item: OrderItem) => void;
}) {
  const { customerId } = useAuth();
  const [form] = Form.useForm();

  const [product, setProduct] = useState<Product>();
  const [products, setProducts] = useState<Product[]>();

  // TODO check 1) product selected; 2) all required properties of item are set;
  const [canSave, setCanSave] = useState<boolean>(false);
  const [canModify, setCanModify] = useState<boolean>(false);

  const isNew = value;

  function fetchProducts() {
    axios
      .get<Product[]>(`/api/products`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch(console.error);
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setCanSave(product != null);
  }, [product]);

  useEffect(() => {
    setCanModify(!customerId || status === "PENDING");
  }, [customerId, status]);

  useEffect(() => {
    if (value && products) {
      const currentProduct = products.find((p) => p.id === value.productId);
      setProduct(currentProduct);

      form.setFieldsValue(value);
    }
  }, [value, products]);

  const getCurrentAttributeValue = (attribute: ProductAttribute) => {
    const currentAttributes = form.getFieldValue(
      "attributes"
    ) as ProductAttribute[];
    if (!currentAttributes) {
      return attribute.initialValue;
    }

    const found = currentAttributes.find(
      (curr) => curr.key === attribute.title
    );
    return found?.value;
  };

  const onProductChange = (currentProduct: Product) => {
    setProduct(currentProduct);

    form.setFieldsValue({
      attributes: currentProduct.attributes.map((a) => ({
        key: a.title,
        value: getCurrentAttributeValue(a),
      })),
    });
  };

  function getAttributeControl(attribute: ProductAttribute) {
    if (attribute.formula) {
      return <FormulaField onChange={() => {}} />;
    }

    if (attribute.type === "NUMBER" || attribute.formula) {
      const minValue = attribute.required ? 1 : 0;
      return <InputNumber min={minValue} />;
    }

    if (attribute.type === "MULTI_TEXT") {
      return <TextArea />;
    }

    if (attribute.type === "MULTI_SELECT") {
      return <MultiSelectField attribute={attribute}/>
    }

    if (attribute.type === "TEXT" && attribute.values) {
      const options = attribute.values.map((v) => ({ value: v, label: v }));
      return <Select options={options} allowClear />;
    }

    return <Input />;
  }

  function getProductDefinition(field) {
    return product.attributes.find((a) => a.title === field.key);
  }

  async function calculateAttribute(name: string) {
    const url = `/api/formulas/${name}`;
    const item = form.getFieldsValue() as OrderItem;
    return axios.post<string>(url, item).then((response) => response.data);
  }

  function onChange(changedFields, allFields) {
    const changedIndex = changedFields.attributes.findIndex((x) => x);
    allFields.attributes.forEach((a, i) => {
      const def = getProductDefinition(a);
      if (def.formula && changedIndex !== i) {
        calculateAttribute(a.key)
          .then((att) => {
            a.value = att;
            const newValues = [...allFields.attributes];
            form.setFieldsValue({ attributes: newValues });
          })
          .catch(console.error);
      }
    });
  }

  function onFinish(values: OrderItem) {
    const productId = product.id;
    const data = { ...values, productId };
    onSave(data);
  }

  return (
    <Form
      form={form}
      name="product"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      onFinish={(values: OrderItem) => onFinish(values)}
      onValuesChange={(changedFields, allFields) =>
        onChange(changedFields, allFields)
      }
      disabled={!canModify}
    >
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item label="Produkt">
        <ProductChooser
          value={product}
          values={products}
          onValueChange={onProductChange}
        />
        {!isNew && (
          <Tooltip title="Zmiana produktu może spowodować utratę wykonanych etapów na produkcji!">
            <WarningTwoTone twoToneColor="orange" />
          </Tooltip>
        )}
      </Form.Item>
      <Form.List name="attributes">
        {(fields) => (
          <>
            {fields.map((field) => {
              const attribute = form.getFieldValue("attributes")[
                field.key
              ] as ProductAttribute;
              const def = getProductDefinition(attribute);
              const control = getAttributeControl(def);
              return (
                <Form.Item
                  key={field.key}
                  label={attribute.key}
                  name={[field.name, "value"]}
                  rules={
                    def.required
                      ? [{ required: true, message: "Wymagane pole" }]
                      : []
                  }
                >
                  {control}
                </Form.Item>
              );
            })}
          </>
        )}
      </Form.List>

      {canModify && (
        <Authorized roles={[...Roles.orderEdit, ...Roles.orderCreate]}>
          <Form.Item wrapperCol={{ sm: { offset: 4, span: 20 } }}>
            <SaveButton onClick={form.submit} disabled={!canSave} />
          </Form.Item>
        </Authorized>
      )}
    </Form>
  );
}
