import { useEffect, useState } from "react";
import { Radio, Input, Form } from "antd";

import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import SaveButton from "app/common/save-button";
import CustomerField from "app/orders/order/recipient/customer-field";
import OrderRecipient from "./OrderRecipient";
import { ORDER_TYPES, ORDER_TYPE_NAMES } from "app/orders/OrderType";
import Customer from "app/customers/Customer";
import { useAuth } from "app/auth/auth-provider";

interface Props {
  recipient?: OrderRecipient;
  onFinish: (recipient: OrderRecipient) => void;
}

export default function OrderRecipientForm({ recipient, onFinish }: Props) {
  const auth = useAuth();
  const [form] = Form.useForm();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [customerValidation, setCustomerValidation] = useState({});
  const [isWholesaler, setIsWholesaler] = useState<boolean>(false);

  useEffect(() => {
    if (recipient) {
      form.setFieldsValue(recipient);
      if (recipient.customer) {
        setSelectedCustomer(recipient.customer);
      }
    }
  }, [form, recipient]);

  useEffect(() => {
    setIsWholesaler(!!auth.customerId);
  }, [auth]);

  function validateCustomer(customer: Customer | undefined) {
    const valid = !!customer;

    setCustomerValidation({
      validateStatus: valid ? "success" : "error",
      help: valid ? null : "Wybierz lub dodaj klienta",
    });
  }

  function onCustomerSelect(customer: Customer | undefined) {
    setSelectedCustomer(customer);
    validateCustomer(customer);
  }

  function beforeFinish(values: OrderRecipient) {
    validateCustomer(selectedCustomer);

    if (!selectedCustomer) {
      return;
    }

    const data = { ...values, customer: selectedCustomer };
    onFinish(data);
  }

  return (
    <Form
      form={form}
      name="recipient"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={{ type: "INSTALLATION" }}
      onFinish={(values: OrderRecipient) => beforeFinish(values)}
    >
      <Form.Item
        name="type"
        label="Typ"
        rules={[{ required: true, message: "Wybierz opcję" }]}
      >
        <Radio.Group buttonStyle="solid" disabled={isWholesaler}>
          {ORDER_TYPES.map((type) => (
            <Radio.Button key={type} value={type}>
              {ORDER_TYPE_NAMES.get(type)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Klient" {...customerValidation}>
        <CustomerField
          value={selectedCustomer}
          onSelect={(currentCustomer: Customer | undefined) =>
            onCustomerSelect(currentCustomer)
          }
          disabled={isWholesaler}
        />
      </Form.Item>
      <Form.Item name="subcontract" label="Podzlecenie">
        <Input />
      </Form.Item>

      <Authorized roles={[...Roles.orderEdit, ...Roles.orderCreate]}>
        <Form.Item wrapperCol={{ sm: { offset: 4, span: 20 } }}>
          <SaveButton onClick={form.submit} />
        </Form.Item>
      </Authorized>
    </Form>
  );
}
