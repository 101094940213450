import { Input, Modal } from "antd";
import { useState } from "react";

export default function AmountGroupModal({
  visible,
  onSave,
  onCancel,
}: {
  visible: boolean;
  onSave: (group: string) => void;
  onCancel: () => void;
}) {
  const [newGroup, setNewGroup] = useState<string>("");

  return (
    <Modal
      title="Grupa"
      open={visible}
      onOk={() => onSave(newGroup)}
      onCancel={onCancel}
      afterClose={() => setNewGroup("")}
    >
      <Input
        value={newGroup}
        onChange={(event) => setNewGroup(event.target.value)}
      />
    </Modal>
  );
}
